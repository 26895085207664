import React from 'react';
import { graphql } from 'gatsby';
import { Breadcrumb } from 'gatsby-plugin-breadcrumb';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import ArticleCard from '../components/resources/ArticleCard';
import HrefLangManager from '../components/HrefLangManager';
import cleanCrumbLabels from '../utils/cleanCrumbLabels';
import { crumbSeparator } from '../utils/variables';

const ResourceCategoryTemplate = ({ pageContext, data, location }) => {
	const {
		category,
		breadcrumb: { crumbs }
	} = pageContext;
	const { nodes } = data.allMdx;

	return (
		<Layout fontSerif={true}>
			<Seo />
			<HrefLangManager
				alternates={[
					['x-default', 'https://www.hear.com/resources/`nodes[0].frontmatter.category`'],
					['en', 'https://www.hear.com/resources/`nodes[0].frontmatter.category`'],
					['en-US', 'https://www.hear.com/resources/`nodes[0].frontmatter.category`'],
					['en-IN', 'https://www.hear.com/in/resources/`nodes[0].frontmatter.category`'],
					['en-CA', 'https://ca.hear.com/resources/`nodes[0].frontmatter.category`']
				]}
			/>
			<div className="o-wrapper">
				<div className="o-row-2p5">
					<h1 className="c-resource-title" style={{ textTransform: 'capitalize' }}>
						{category.replace(/-/g, ' ')}
					</h1>
				</div>
				<div className="o-row-2p5">
					<Breadcrumb location={location} crumbSeparator={crumbSeparator} crumbs={cleanCrumbLabels(crumbs)} />
				</div>
				<div className="o-row-4">
					<div className="c-resource-cards-wrapper">
						{nodes.map((post, i) => (
							<ArticleCard
								key={i}
								url={`/resources/${post.frontmatter.category}/${post.frontmatter.slug}`}
								title={post.frontmatter.articleCardTitle}
								description={post.frontmatter.articleCardDescription}
							/>
						))}
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default ResourceCategoryTemplate;

export const pageQuery = graphql`
	query ($category: String) {
		allMdx(limit: 1000, filter: { frontmatter: { category: { eq: $category } } }) {
			nodes {
				frontmatter {
					category
					slug
					articleCardTitle
					articleCardDescription
				}
			}
		}
	}
`;
